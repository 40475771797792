<template>
  <div class="mt-1">
    <h1 class="mb-1 color-secondary">Mis Tarjetas</h1>

    <div class="item justify-space-between align-center flex mb-1">
      <div class="flex align-center">
        <svg class="path-initial" width="24" height="7" viewBox="0 0 24 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4122 2.23782C12.3985 3.21295 13.3744 3.75708 14.1095 4.08062C14.8647 4.41255 15.1184 4.62543 15.1154 4.9223C15.1098 5.37659 14.513 5.5771 13.9545 5.58489C12.9802 5.59853 12.4136 5.34729 11.9633 5.15728L11.6122 6.64072C12.0641 6.82878 12.9008 6.9928 13.7685 7C15.8052 7 17.1377 6.09193 17.1449 4.68403C17.1529 2.89719 14.4085 2.79829 14.4273 1.99962C14.4338 1.75745 14.6896 1.49902 15.2502 1.43331C15.5277 1.40012 16.2938 1.37472 17.1623 1.73594L17.5031 0.300684C17.0361 0.147082 16.4358 0 15.6884 0C13.7714 0 12.4231 0.920428 12.4122 2.23782ZM20.7786 0.123627C20.4067 0.123627 20.0933 0.319567 19.9534 0.620252L17.044 6.89458H19.0793L19.4842 5.88363H21.9713L22.2062 6.89458H24L22.4347 0.123627H20.7786ZM21.0633 1.95271L21.6506 4.49528H20.0421L21.0633 1.95271ZM9.94462 0.123712L8.34037 6.89449H10.2798L11.8833 0.123542H9.94462V0.123712ZM7.07559 0.123542L5.05697 4.73221L4.24041 0.813652C4.14459 0.376215 3.76622 0.123627 3.34603 0.123627H0.0462187L0 0.320244C0.677437 0.453016 1.44712 0.667162 1.91344 0.896296C2.19881 1.03626 2.28019 1.15862 2.37394 1.49123L3.92053 6.89458H5.97L9.11213 0.123627H7.07559"
            fill="url(#paint0_linear_734_12689)"/>
          <defs>
            <linearGradient id="paint0_linear_734_12689" x1="11.0338" y1="7.14042" x2="11.2168" y2="-0.0497338"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#222357"/>
              <stop offset="1" stop-color="#254AA5"/>
            </linearGradient>
          </defs>
        </svg>
        <p class="ml-1 alt color-20">Visa *1234</p>
      </div>
      <button class="btn-secondary btn-alt">Eliminar</button>
    </div>

    <hr class="bg-color-20">

    <div class="item justify-space-between align-center flex mb-1">
      <div class="flex align-center">
        <svg class="path-initial" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#EA001B"/>
          <path d="M17 14C20.866 14 24 10.866 24 7C24 3.13401 20.866 0 17 0C13.134 0 10 3.13401 10 7C10 10.866 13.134 14 17 14Z" fill="#FFA200" fill-opacity="0.8"/>
        </svg>

        <p class="ml-1 alt color-20">Visa *1234</p>
      </div>
      <button class="btn-secondary btn-alt">Eliminar</button>
    </div>

    <hr class="bg-color-20">

    <div class="item justify-space-between align-center flex mb-1">
      <div class="flex align-center">
        <svg class="path-initial" width="24" height="7" viewBox="0 0 24 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4122 2.23782C12.3985 3.21295 13.3744 3.75708 14.1095 4.08062C14.8647 4.41255 15.1184 4.62543 15.1154 4.9223C15.1098 5.37659 14.513 5.5771 13.9545 5.58489C12.9802 5.59853 12.4136 5.34729 11.9633 5.15728L11.6122 6.64072C12.0641 6.82878 12.9008 6.9928 13.7685 7C15.8052 7 17.1377 6.09193 17.1449 4.68403C17.1529 2.89719 14.4085 2.79829 14.4273 1.99962C14.4338 1.75745 14.6896 1.49902 15.2502 1.43331C15.5277 1.40012 16.2938 1.37472 17.1623 1.73594L17.5031 0.300684C17.0361 0.147082 16.4358 0 15.6884 0C13.7714 0 12.4231 0.920428 12.4122 2.23782ZM20.7786 0.123627C20.4067 0.123627 20.0933 0.319567 19.9534 0.620252L17.044 6.89458H19.0793L19.4842 5.88363H21.9713L22.2062 6.89458H24L22.4347 0.123627H20.7786ZM21.0633 1.95271L21.6506 4.49528H20.0421L21.0633 1.95271ZM9.94462 0.123712L8.34037 6.89449H10.2798L11.8833 0.123542H9.94462V0.123712ZM7.07559 0.123542L5.05697 4.73221L4.24041 0.813652C4.14459 0.376215 3.76622 0.123627 3.34603 0.123627H0.0462187L0 0.320244C0.677437 0.453016 1.44712 0.667162 1.91344 0.896296C2.19881 1.03626 2.28019 1.15862 2.37394 1.49123L3.92053 6.89458H5.97L9.11213 0.123627H7.07559"
            fill="url(#paint0_linear_734_12689)"/>
          <defs>
            <linearGradient id="paint0_linear_734_12689" x1="11.0338" y1="7.14042" x2="11.2168" y2="-0.0497338"
                            gradientUnits="userSpaceOnUse">
              <stop stop-color="#222357"/>
              <stop offset="1" stop-color="#254AA5"/>
            </linearGradient>
          </defs>
        </svg>
        <p class="ml-1 alt color-20">Visa *1234</p>
      </div>
      <button class="btn-secondary btn-alt">Eliminar</button>
    </div>

    <hr class="bg-color-20">

    <button class="mt-4">Agregar Nueva Tarjeta</button>

  </div>
</template>
